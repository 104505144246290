<style lang="less" scoped>
    @import url('~@/lib/base.less');
    @searchHeight: .pxToRem(98) [];
    .page-citylist {
        position: relative;
        background: white;
        .search {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10;
            height: @searchHeight;
            padding: .pxToRem(20) [] .pxToRem(20) [] .pxToRem(12) [] .pxToRem(20) [];
            background: white;
            box-sizing: border-box;
            .wrap {
                position: absolute;
                left: .pxToRem(20) [];
                right: .pxToRem(20) [];
                height: .pxToRem(66) [];
                line-height: .pxToRem(66) [];
                transition: all 0.1s;
                .btn-cancel {
                    .base-center-y;
                    right: 0;
                    width: .pxToRem(110) [];
                    transform: translate(100%, -50%);
                    text-indent: .pxToRem(20) [];
                    color: @theme_color_first;
                }
            }
            .cell {
                display: flex;
                flex-direction: row;
                border-radius: .pxToRem(40) [];
                background: #f7f8fa;
                overflow: hidden;
            }
            .img {
                .sq(32);
                vertical-align: middle;
                margin-top: .pxToRem(18) [];
                margin-left: .pxToRem(24) [];
                margin-right: .pxToRem(20) [];
            }
            .input {
                font-size: .pxToRem(28) [];
                background: #f7f8fa;
                height: .pxToRem(66) [];
                line-height: .pxToRem(66) [];
                padding: 0;
                flex: 1;
            }

            .close {
                .base-center-y;
                right: .pxToRem(20) [];
                .sq(32);
            }

            &.show-cancel {
                .wrap {
                    right: .pxToRem(110) [];
                }
            }
        }
        .scroll-view {
            position: absolute;
            top: @searchHeight;
            left: 0;
            right: 0;
            bottom: 0;
            height: calc(100vh - @searchHeight);
            background: white;
        }
        .module {
            padding: 0 .pxToRem(30) [];
            background: white;
            .hd {
                font-size: .pxToRem(30) [];
                font-weight: 600;
                margin-top: .pxToRem(50) [];
                margin-bottom: .pxToRem(10) [];
                color: rgba(0, 0, 0, 0.9);
            }
            .cell-name {
                box-sizing: border-box;
                font-size: .pxToRem(28) [];
                width: .pxToRem(190) [];
                height: .pxToRem(80) [];
                border-radius: .pxToRem(8) [];
                text-align: center;
                line-height: .pxToRem(80) [];
                margin-top: .pxToRem(20) [];
                margin-right: .pxToRem(16) [];
                display: inline-block;
                color: rgba(0, 0, 0, 0.9);
                background: white;
                border: .pxToRem(1) [] solid #313131;
                overflow: hidden;
                span {
                    .overflow(1);
                }
            }
            .now {
                color: @theme_color_first;
                background: #fdf0f0;
                border: .pxToRem(1) [] solid @theme_color_first;
            }
        }
        .current-city {
            .fail-city {
                display: inline-block;
                height: .pxToRem(82) [];
                border-radius: .pxToRem(41) [];
                background: #f7f8fa;
                text-align: center;
                line-height: .pxToRem(82) [];
                margin-bottom: .pxToRem(20) [];
                margin-right: .pxToRem(16) [];
                color: #e3c119;
                font-size: .pxToRem(28) [];
                padding: 0 .pxToRem(40) [];
            }
        }
        .more-city {
            margin-top: .pxToRem(42) [];
            margin-bottom: 0;
            .bd {
                .cell {
                    overflow: hidden;
                    .t {
                        height: .pxToRem(48) [];
                        margin-top: .pxToRem(30) [];
                        margin-bottom: .pxToRem(14) [];
                        span {
                            .base-center-y;
                            .sq(48);
                            line-height: .pxToRem(48) [];
                            text-align: center;
                            border-radius: .pxToRem(24) [];
                            box-sizing: border-box;
                            border: .pxToRem(1) [] solid #d9d9d9;
                            font-size: .pxToRem(24) [];
                            color: rgba(0, 0, 0, 0.9);
                            line-height: .pxToRem(60) [];
                            background: #f2f2f2;
                            line-height: .pxToRem(48) [];
                        }
                        &:after {
                            content: ' ';
                            .base-center-y;
                            left: .pxToRem(73) [];
                            width: .pxToRem(528) [];
                            height: .pxToRem(1) [];
                            background: rgba(0, 0, 0, 0.15);
                        }
                    }
                    .names {
                        font-size: 0;
                        .city {
                            padding-left: .pxToRem(20) [];
                            height: .pxToRem(100) [];
                            font-size: .pxToRem(32) [];
                            line-height: .pxToRem(100) [];
                            border-bottom: 1px solid #ebebed;
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            .cell-name {
                border: .pxToRem(1) [] solid #e9e9e9;
                margin-top: .pxToRem(16) [];
            }
        }
        .city-numers {
            position: fixed;
            width: .pxToRem(83) [];
            right: 0;
            top: .pxToRem(143) [];
            .cell {
                font-size: .pxToRem(22) [];
                width: .pxToRem(83) [];
                height: .pxToRem(36) [];
                line-height: .pxToRem(36) [];
                a {
                    .base-center;
                    color: rgba(0, 0, 0, 0.9);
                    font-size: .pxToRem(24) [];
                    font-weight: 600;
                }
            }
        }
        .search-list {
            height: 100vh;
            box-sizing: border-box;
            padding-top: calc(@searchHeight + .pxToRem(10) []);
            .names {
                font-size: 0;
                .city {
                    margin-left: .pxToRem(50) [];
                    margin-right: .pxToRem(50) [];
                    font-size: .pxToRem(28) [];
                    height: .pxToRem(88) [];
                    line-height: .pxToRem(88) [];
                    border-bottom: .pxToRem(1) [] solid #ebebed;
                    box-sizing: border-box;
                }
            }
        }
    }
</style>

<template>
    <div class="page-citylist page">
        <div class="search"
             :class="{'show-cancel':searchStatus}">
            <div class="wrap">
                <div class="cell">
                    <img class="img"
                         src="@/assets/icons/icon-search.png" />
                    <input class="input"
                           placeholder="国内城市/区域"
                           @blur="inputBlur"
                           v-model="searchText" />
                </div>
                <div class="btn-cancel"
                     v-if="searchStatus"
                     @click="cancelSearch">取消</div>
            </div>
        </div>
        <div class="scroll-view"
             v-if="!searchStatus">
            <div class="current-city module">
                <div class="hd t">
                    <span class="txt">当前</span>
                </div>
                <div class="bd">
                    <div class="cell-name now"
                         v-if="cityName"
                         @click="clickCurrent">
                        <span class="txt">{{cityName}}</span>
                    </div>
                    <div class="fail-city"
                         v-else>
                        <span class="txt">定位失败，请重试</span>
                    </div>
                </div>
            </div>
            <div class="history-city module"
                 v-if="hotCityList.length">
                <div class="hd t">
                    <span class="txt">热门</span>
                </div>
                <div class="bd">
                    <div class="cell-name"
                         v-for="(item,index) in hotCityList"
                         @click="setLocation(item)"
                         :key="index">
                        <span class="txt">{{item.name}}</span>
                    </div>
                </div>
            </div>
            <div class="more-city module">
                <div class="hd t">
                    <span>更多</span>
                </div>
                <div class="bd">
                    <div class="cell"
                         v-for="(item , index) in allCityList"
                         :key="index"
                         :id="item.letter">
                        <div class="t">
                            <span>{{item.letter}}</span>
                        </div>
                        <div class="names">
                            <div class="cell-name"
                                 v-for="(v,i) in item.cities"
                                 @click="setLocation(v)"
                                 :key="i">
                                <span>{{v.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class='search-list'
             v-if="searchStatus">
            <div class='cell'
                 v-for="(item , index ) in searchList"
                 :key="index">
                <div class='names'>
                    <div class='city'
                         v-for="(v,i) in item.cities"
                         @click="setLocation(v)"
                         :key="i">
                        <span>{{v.name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="city-numers"
             v-if="!searchStatus">
            <div class="cell"
                 v-for="(item, index) in allCityList"
                 @click="clickQuick(item, index)"
                 :key="index">
                <a>{{item.letter}}</a>
            </div>
        </div>
    </div>
</template>
<script>
    import { QZ_LOCATION_HISTORY_LIST } from '@/lib/constant';
    import { GET_HOST_CITY_LIST, GET_CITY_LIST } from 'api';
    import { PAGE_ROOT } from 'page';
    export default {
        data() {
            return {
                type: 0,
                isOpenBack: false,
                searchText: '',
                searchStatus: false,
                hotCityList: [],
                allCityList: [],
                searchList: [],
                source: '',
            }
        },
        computed: {
            cityName() {
                return this.$store.state.location.currentLocation.city.name || this.$store.state.location.selectLocation.city.name || false
            },
        },
        watch: {
            searchText(val) {
                if (val.length > 0) {
                    this.searchStatus = true;
                    this.getCityList(1);
                }
                else {
                    this.searchStatus = false;
                    this.searchList = [];
                }
            }
        },
        created() {
            this.type = this.$util.getQueryString('type');
            this.source = this.$util.getQueryString('source');
            if (this.type == 1 && !this.cityName) {
                this.fobiddeBack();
            }
            else if (this.type == 2) {
                this.isOpenBack = true;
            }
            else if (this.type == 3) {
                // 选择地址过来切换
            }
            else {
                this.enableBack();
            }
            this.getHotCityList();
            this.getCityList();
        },
        methods: {
            inputBlur() {
                setTimeout(() => {
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100)
            },
            cancelSearch() {
                this.searchText = "";
            },
            getHotCityList() {
                this.$util.post(GET_HOST_CITY_LIST).then(res => {
                    if (res.success) {
                        this.hotCityList = res.data;
                    }
                })
            },
            getCityList(type = 0) {
                this.$util.post(GET_CITY_LIST, { name: this.searchText }).then(res => {
                    if (res.success) {
                        if (type == 1) {
                            this.searchList = res.data;
                        }
                        else {
                            this.allCityList = res.data;
                        }
                    }
                })
            },
            clickCurrent() {
                let _setLocation = {};
                if (this.$store.state.location.currentLocation.city.id) {
                    _setLocation = this.$store.state.location.currentLocation;
                }
                else if (this.$store.state.location.selectLocation.city.id) {
                    _setLocation = this.$store.state.location.selectLocation;
                }
                this.$store.commit('setSelectLocation', _setLocation);
                this.enableBack();
                if (this.source) {
                    if (this.source.indexOf(this.$route.path) > -1) {
                        this.$util.pageJump(PAGE_ROOT);
                    }
                    else {
                        window.location.href = this.source;
                    }
                }
                else {
                    this.$util.pageBack();
                }
            },
            setLocation(item) {
                let data = {}
                if (this.$store.state.location.currentLocation.city && (item.name == this.$store.state.location.currentLocation.city.name || (item.city && item.city.name == this.$store.state.location.currentLocation.city.name))) {
                    data = this.$store.state.location.currentLocation;
                }
                else {
                    data = {
                        lon: item.lon || item.longitude,
                        lat: item.lat || item.latitude,
                        city: {
                            id: item.id || item.city.id,
                            name: item.name || item.city.name
                        },
                    }
                }
                this.$store.commit('setSelectLocation', data)
                
                this.enableBack();
                if (this.source) {
                    if (this.source.indexOf(this.$route.path) > -1) {
                        this.$util.pageJump({
                            path: PAGE_ROOT,
                            config: {
                                jumpType: 1
                            }
                        });
                    }
                    else {
                        window.location.replace(this.source)
                    }

                }
                else {
                    this.$util.pageBack();
                }

            },
            //防止页面后退
            fobiddeBack() {
                if (!this.isOpenBack) {
                    console.error('防止页面后退')
                    this.isOpenBack = true;
                    history.pushState(null, null, document.URL);
                    window.addEventListener('popstate', this.backCommon)
                }
            },
            //启用浏览器返回
            enableBack() {
                if (this.isOpenBack) {
                    console.error('启用浏览器返回')
                    this.isOpenBack = false;
                    history.go(-1);
                    window.removeEventListener('popstate', this.backCommon)
                }
            },
            backCommon() {
                history.pushState(null, null, document.URL);
            },
            // 记录点击次数
            clickQuick(item, index) {
                document.getElementById(item.letter).scrollIntoView();
            }
        },
    }
</script>
